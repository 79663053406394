//image_id
//filter_id

import { buryPointReport } from "../../../api/image-filter";
import axios from "axios";
var md5 = require("md5");
import { getSessionsSource, getDeviceType } from "../../index.js";

export function filterPreview (eventName, filterId) {
  const timestamp = Date.now();
  const deviceId = localStorage.getItem("browserId");
  const sessionId = sessionStorage.getItem("sessionId");
  const signId = md5(1 + deviceId + timestamp + eventName + "gJUyT9qGK");
  //图片id
  const imageId = sessionStorage.getItem("sourceImageId");
  const params = {
    app_id: 1,
    app_name: "ai_tools",
    user_id: "",
    device_id: deviceId, //当前设备浏览器唯一id
    session_id: sessionId, //当前浏览器页面的sessionid
    timestamp: timestamp, // 当前时间戳，13 位毫秒级
    device_type: getDeviceType(),
    session_source: getSessionsSource(),
    event: {
      event_name: eventName,
      properties: {
        filter_id: filterId,
        source_image_id: imageId,
      },
    },
    sign: signId,
  };
  buryPointReport({ ...params });
}
